import React, {Component} from "react";
import { Row, Col } from "reactstrap";

class EmissorBeneficios extends Component {

  state = {
    btnSelected: 0
  }

  render() {

    return (
      <>
        <div className="element beneficios-emissor">
          <Col className="justify-content-md-center">
            <Row lg="12" md="12">
              <h3 className="beneficios-titulo">
                BENEFÍCIOS
              </h3>
            </Row>

            <Row lg="12" md="12" sm="12" className="beneficios-row">
              <Col lg="4" md="4" sm="6">
                <img
                  alt="emissor-beneficios-1"
                  className="beneficios-icons"
                  src={require("assets/img/emissor-b-1.png")}
                ></img>
                <br />
                <span>
                  Facilitar a emissão de notas fiscais, com
                  simplicidade, qualidade e rapidez.
                </span>
              </Col>
              <Col lg="4" md="4" sm="6">
                <img
                  alt="emissor-beneficios-1"
                  className="beneficios-icons"
                  src={require("assets/img/emissor-b-2.png")}
                ></img>
                <br />
                <span>
                Redução de custos com pagamento
                de impostos indevidos.
                </span>
              </Col>
              <Col lg="4" md="4">
                <img
                  alt="emissor-beneficios-1"
                  className="beneficios-icons"
                  src={require("assets/img/emissor-b-3.png")}
                ></img>
                <br />
                <span>
                Automatizar todo o processo
                de execução de vendas.
                </span>
              </Col>
            </Row>
            <Row lg="12" md="12" className="beneficios-row">
              <Col lg="4" md="4">
                <img
                  alt="emissor-beneficios-1"
                  className="beneficios-icons"
                  src={require("assets/img/emissor-b-4.png")}
                ></img>
                <br />
                <span>
                Aumentar e agilizar
                suas vendas.
                </span>
              </Col>
              <Col lg="4" md="4">
                <img
                  alt="emissor-beneficios-1"
                  className="beneficios-icons"
                  src={require("assets/img/emissor-b-5.png")}
                ></img>
                <br />
                <span>
                Economizar tempo
                e dinheiro.
                </span>
              </Col>
              <Col lg="4" md="4">
                <img
                  alt="emissor-beneficios-1"
                  className="beneficios-icons"
                  src={require("assets/img/emissor-b-6.png")}
                ></img>
                <br />
                <span>
                Segurança e comodidade, sem
                medo de perder os arquivos.
                </span>
              </Col>
            </Row>

          </Col>
          
        </div>
      </>
    );
  }
}

export default EmissorBeneficios;