import React, {Component} from "react";
import {
  Col
} from "reactstrap";

class NossaHistoria extends Component {

  state = {
    btnSelected: 0
  }

  form = {
    nome: '',
    assunto: '',
    telefone: '',
    email: '',
    mensagem: ''
  }

  render() {

    return (
      <>
        <div className="section element">
            <div className="justify-content-md-center flex-row nossa-historia">
              <Col lg="5" md="12">
                <img
                  alt="nossa-historia"
                  className="nossa-historia-img"
                  src={require("assets/img/nossa-historia-card.jpg")}
                ></img>
              </Col>
              <Col lg="7" md="12">
                <span className='nossa-historia-titulo'>GERMAN TECH SISTEMAS</span><br /><br />
                <div className='nossa-historia-subtitulo'>
                    <span>
                    Há mais de 10 anos simplificando e transformando a tecnologia em resultados. Presente em mais de 1500 municípios, com mais de 1300 clientes dos mais diversos segmentos em todo o Brasil.
                    <br /><br />
                    Fundada em 17 de fevereiro de 2010, pelo empresário Rafael Ricardo Gruber, a German Tech Sistemas tem uma ampla sede na Rua Santos Dumont, 2005, no centro da cidade de Toledo. Composta por uma equipe altamente capacitada, dentre eles bacharéis em sistemas de informação, bacharéis em direito, contadores, economistas e administradores. Nossa equipe está sempre focada em atender os clientes para proporcionar a eles os melhores resultados. Assegurando sistemas inteligentes, temos como meta fornecer o melhor sistema para o gerenciamento dos mais diversos segmentos empresariais.
                    <br /><br />
                    Estamos em contínuo treinamento e aperfeiçoamento. Buscamos inovações no Brasil e no exterior para melhor atender as necessidades de nossos clientes.
                    </span>
                </div>
              </Col>
            </div>
        </div>
      </>
    );
  }
}

export default NossaHistoria;