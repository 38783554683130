import React, { Component } from "react";
import {
  Col,
} from "reactstrap";

import { sendEmailParceiro, sendEmailTrabalhe } from "../../helpers/sendEmail";
class ContatoContatos extends Component {
  state = {
    btnSelected: 0,
    dialogParceiroOpen: false,
    dialogTrabalheOpen: false,
    alertVisible: false,
    filename: "",
  };

  formParceiro = {
    nome: "",
    cidade: "",
    email: "",
    telefone: "",
    celular: "",
    mensagem: "",
  };

  formTrabalhe = {
    nome: "",
    email: "",
    cargo: "",
    cidade: "",
    celular: "",
    telefone: "",
    curso: "",
    mensagem: "",
    curriculo: null,
    extensao: "",
    filename: "",
  };

  handleParceiroOpen = () => {
    this.setState({ dialogParceiroOpen: true });
  };

  handleParceiroClose = () => {
    this.setState({ dialogParceiroOpen: false });
  };

  handleTrabalheOpen = () => {
    this.setState({ dialogTrabalheOpen: true });
  };

  handleTrabalheClose = () => {
    this.setState({ dialogTrabalheOpen: false });
  };

  handleParceiroSubmit = (e) => {
    e.preventDefault();
    sendEmailParceiro(this.formParceiro);
    this.resetFormParceiro();
    document.getElementById("parceiroForm").reset();
    this.setState({ alertVisible: true });
    this.handleParceiroClose();
    setTimeout(() => {
      this.setState({ alertVisible: false });
    }, 2000);
  };

  resetFormParceiro = () => {
    this.formParceiro = {
      nome: "",
      cidade: "",
      email: "",
      telefone: "",
      celular: "",
      mensagem: "",
    };
  };

  handleTrabalheSubmit = (e) => {
    e.preventDefault();
    sendEmailTrabalhe(this.formTrabalhe);
    this.resetFormTrabalhe();
    document.getElementById("trabalheForm").reset();
    this.setState({ alertVisible: true });
    this.handleTrabalheClose();
    setTimeout(() => {
      this.setState({ alertVisible: false });
    }, 2000);
  };

  resetFormTrabalhe = () => {
    this.formTrabalhe = {
      nome: "",
      cidade: "",
      email: "",
      telefone: "",
      celular: "",
      mensagem: "",
      curriculo: null,
      extensao: "",
      filename: "",
    };
    this.setState({ filename: "" });
  };

  baixarModelo = () => {
    // FileSaver.saveAs(curriculo, "curriculo.docx");
  };

  uploadFile = (file) => {
    var reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = () => {
      var dataUri = "data:" + file.type + ";base64," + btoa(reader.result);
      this.formTrabalhe.curriculo = dataUri;
      let re = /(?:\.([^.]+))?$/;
      let extensao = re.exec(file.name)[1];
      this.formTrabalhe.extensao = extensao;
      this.formTrabalhe.filename = re.exec(file.name)[0];
      this.setState({ filename: file.name });
    };
    reader.onerror = () => {
      console.log("there are some problems");
    };
  };

  render() {
    return (
      <>
        <div className="section element">
          <div className="justify-content-md-center">
            <Col lg="12" md="12" className="contato-titulo">
              <span>Contatos</span>
              <br />
              <br />
            </Col>
          </div>
          <div className="flex-row justify-content-md-center contatos-linha">
            <div className="contatos-left">
              <span className="contato-subtitulo">TELEFONES</span>
              <hr className="solid hr-contatos"></hr>
              <br />
              <br />
            </div>
            <div className="contatos-right">
              <span className="contato-subtitulo2">Comercial: </span>
              <span className="contato-texto">
                PR (45) 3056-9084 / SP (11) 2311-4077
              </span>
              <br />

              <span className="contato-subtitulo2">Suporte: </span>
              <span className="contato-texto">
                (45) 3056-9090 / (45) 3056-9091
              </span>
              <br />

              <span className="contato-subtitulo2">Whatsapp: </span>
              <span className="contato-texto">
                PR (45) 9 9810-0317 / SP (11) 9 9394-1498
              </span>
              <br />

              <span className="contato-subtitulo2">Financeiro: </span>
              <span className="contato-texto">
                (45) 3056-9013 / (45) 9 9810-0317
              </span>
              <br />

              <span className="contato-subtitulo2">Plantão: </span>
              <span className="contato-texto">
                PR (45) 9 9848-8365 / SP (11) 9 9733-6007
              </span>
              <br />
              <br />
              <span className="contato-texto">
                Números exclusivos para solicitação de informações, orçamentos,
                suporte especializado, assistência de plantão e muito mais.
              </span>
              <br />
              <div
                className="contato-text-titulo"
                style={{ paddingTop: 20, fontSize: 18 }}
              >
                HORÁRIO DE ATENDIMENTO
              </div>
              <br />
              <span className="contato-texto">
                Atendimento de suporte segunda-feira a sexta-feira das 8h às 18h
                e nos sábados das 8h às 12h. Atendimento de plantão todos os
                dias até às 22 horas, incluindo sábados, domingos e feriados!
                <br />
              </span>
            </div>
          </div>

          <div
            className="flex-row justify-content-md-center contatos-linha"
            style={{ marginTop: 25 }}
          >
            <div className="contatos-left">
              <span className="contato-subtitulo">ENDEREÇOS</span>
              <hr className="solid hr-contatos"></hr>
              <br />
              <br />
            </div>
            <div className="contatos-right">
              <span className="contato-subtitulo2">Toledo - PR: </span>
              <span className="contato-texto">
                Rua Santos Dumont, 2005 - Centro Toledo - PR, 85900-010
              </span>
              <br />

              <span className="contato-subtitulo2">Cascavel - PR: </span>
              <span className="contato-texto">
                Rua Recife,1360, Sala 1, Centro 85.810-031
              </span>
              <br />

              <span className="contato-subtitulo2">
                São Caetano do Sul - SP:{" "}
              </span>
              <span className="contato-texto">
                Alameda Terracota, 185, 1º andar, Sala 132, Cerâmica -
                09.531-190
              </span>
              <br />
            </div>
          </div>

          <div
            className="flex-row justify-content-md-center contatos-linha"
            style={{ marginTop: 25 }}
          >
            <div className="contatos-left">
              <span className="contato-subtitulo">OUTROS CANAIS</span>
              <hr className="solid hr-contatos"></hr>
              <br />
              <br />
            </div>
            <div className="contatos-right">
              <span className="contato-subtitulo2">E-mail: </span>
              <span className="contato-texto">comercial@germantech.com.br</span>
              <br />

              <span className="contato-subtitulo2">Facebook: </span>
              <span className="contato-texto">
                https://www.fb.com/germantechsistemas
              </span>
              <br />

              <span className="contato-subtitulo2">Instagram: </span>
              <span className="contato-texto">@sistemasgermantech</span>
              <br />

              <span className="contato-subtitulo2">Youtube: </span>
              <span className="contato-texto">
                https://bit.ly/germantechsistemas
              </span>
              <br />
              <span className="contato-subtitulo2">Linkedin: </span>
              <span className="contato-texto">
                https://www.linkedin.com/company/german-tech-sistemas
              </span>
              <br />
            </div>
          </div>

          <div
            className="flex-row justify-content-md-center contatos-linha"
            style={{ marginTop: 25 }}
          >
            <div className="contatos-left">
              <span className="contato-subtitulo">TRABALHE CONOSCO</span>
              <hr className="solid hr-contatos"></hr>
              <br />
              <br />
            </div>
            <div className="contatos-right">
              <span className="contato-subtitulo2">
                Envie seu currículo diretamente via e-mail para:{" "}
              </span>
              <span className="contato-texto">raul@germantech.com.br</span>
              <br />

              <span className="contato-subtitulo2">
                Envie através do WhatsApp:{" "}
              </span>
              <span className="contato-texto">
                Clique aqui
                <img
                  alt=""
                  style={{ marginLeft: 4, cursor: "pointer" }}
                  width={"4%"}
                  src={require("assets/img/wpp.png")}
                  onClick={() =>
                    window.open(
                      "https://api.whatsapp.com/send?phone=554530569085&text=Ol%C3%A1%2C%20vim%20atrav%C3%A9s%20do%20site%20da%20GermanTech%20Sistemas%20e%20gostaria%20de%20enviar%20meu%20curr%C3%ADculo.%20Enviarei%20o%20arquivo%20em%20PDF%20na%20sequ%C3%AAncia"
                    )
                  }
                ></img>
              </span>
              <br />
            </div>
          </div>

          <div
            className="flex-row justify-content-md-center contatos-linha"
            style={{ marginTop: 25 }}
          >
            <div className="contatos-left">
              <span className="contato-subtitulo">SEJA UM PARCEIRO</span>
              <hr className="solid hr-contatos"></hr>
              <br />
              <br />
            </div>
            <div className="contatos-right">
              <span className="contato-subtitulo2">
                A German Tech valoriza alianças estratégicas que agreguem valor
                e gerem ganhos para todos os envolvidos. No varejo, firmamos
                parcerias com revendas de equipamentos de informática e
                automação comercial, bem como lojas especializadas em
                assistência técnica em informática em geral, que podem indicar
                e/ou revender nosso sistema mediante comissionamento. Se sua
                empresa tem interesse em firmar alianças conosco.
              </span>
              <br />
              <br />

              <span className="contato-subtitulo2">
                Entre em contato diretamente via e-mail:{" "}
              </span>
              <span className="contato-texto">raul@germantech.com.br</span>
              <br />

              <span className="contato-subtitulo2">
                Entre em contato através do WhatsApp:{" "}
              </span>
              <span className="contato-texto">
                Clique aqui
                <img
                  alt=""
                  style={{ marginLeft: 4, cursor: "pointer" }}
                  width={"4%"}
                  src={require("assets/img/wpp.png")}
                  onClick={() =>
                    window.open(
                      "https://api.whatsapp.com/send?phone=554530569085&text=Ol%C3%A1!%20Vim%20atrav%C3%A9s%20do%20site%20e%20gostaria%20de%20obter%20mais%20informa%C3%A7%C3%B5es%20relacionadas%20a%20parceria"
                    )
                  }
                ></img>
              </span>
              <br />
            </div>
          </div>

          <div
            className="flex-row justify-content-md-center contatos-linha"
            style={{ marginTop: 55 }}
          >
            <div className="contato-redes-sociais">
              <img
                alt=""
                className="contato-social-btn pointer"
                src={require("assets/img/wpp-comparativo.png")}
                onClick={() => window.open("https://wa.me/554530569085")}
              ></img>
              {/* <img
                        alt=""
                        className="contato-social-btn pointer"
                        src={require("assets/img/telefone-comparativo.png")}
                    ></img> */}
              <img
                alt=""
                className="contato-social-btn pointer"
                onClick={() => {
                  window.location.href = `mailto:contato@germantech.com.br`;
                }}
                src={require("assets/img/email-icon.png")}
              ></img>
              <img
                alt=""
                className="contato-social-btn pointer"
                src={require("assets/img/map-icon.png")}
                onClick={() =>
                  window.open("https://goo.gl/maps/QrDsmhEgtfLwu3cC8")
                }
              ></img>
              <img
                alt=""
                className="contato-social-btn pointer"
                src={require("assets/img/instagram-comparativo.png")}
                onClick={() =>
                  window.open("https://www.instagram.com/sistemasgermantech/")
                }
              ></img>
              <img
                alt=""
                className="contato-social-btn pointer"
                src={require("assets/img/facebook-comparativo.png")}
                onClick={() =>
                  window.open("https://www.facebook.com/germantechsistemas/")
                }
              ></img>
              <img
                alt=""
                className="contato-social-btn pointer"
                src={require("assets/img/youtube-comparativo.png")}
                onClick={() => window.open("https://bit.ly/germantechsistemas")}
              ></img>
            </div>
          </div>

          {/* <div className="flex-row justify-content-md-center contatos-linha" style={{marginTop: 55}}>
              <div className="contato-botoes">

                <Button
                    className="btn-parceiro-contato"
                    color="info"
                    type='button'
                    size="lg"
                    onClick={this.handleParceiroOpen}
                >
                    SEJA UM PARCEIRO
                </Button>
                <br />
                <Button
                    className="btn-trabalhe-contato"
                    color="info"
                    type='button'
                    size="lg"
                    onClick={this.handleTrabalheOpen}
                >
                    TRABALHE CONOSCO
                </Button>
              </div>
            </div> */}

          {/* <Dialog open={this.state.dialogParceiroOpen} onClose={this.handleParceiroClose} aria-labelledby="form-dialog-title">
              <div className="dialog-conteudo">
                <div className="dialog-conteudo-texto">
                A German Tech valoriza alianças estratégicas que agreguem valor e gerem ganhos para todos os envolvidos.
                <br /><br />
                No varejo, firmamos parcerias com revendas de equipamentos de informática e automação comercial, bem como lojas especializadas em assistência técnica em informática em geral, que podem indicar e/ou revender nosso sistema mediante comissionamento.
                <br /><br />
                Na área fiscal e contábil selecionamos alguns escritórios de contabilidade que além de utilizarem nossa tecnologia para simplificar a rotina interna do escritório, ficam habilitados para participar do processo de suporte no sistema para seus clientes, com ganhos para todos os envolvidos.
                <br /><br />
                Na área de software development estamos abertos para contribuir com outras empresas de desenvolvimento, compartilhando nossa avançada expertise nas áreas fiscal e contábil, agregando valor aos sistemas.
                <br /><br />
                Se sua empresa tem interesse em firmar alianças conosco, por favor preencha os dados abaixo que em breve entraremos em contato
                <br />
                </div>
                <Form id="parceiroForm" className="form" onSubmit={this.handleParceiroSubmit}>
                  <CardBody>
                    <Col>
                        <Row sm="12" md="12" lg="12">
                          <Col sm="12" md="12" lg="12">
                            <InputGroup className={"no-border input-lg"}>
                                <Input
                                    className="contato-input"
                                    placeholder="Nome"
                                    type="text"
                                    maxLength={60}
                                    onChange={(e)=> this.formParceiro.nome = e.target.value }
                                    required
                                    ></Input>
                            </InputGroup>
                          </Col>
                        </Row>

                        <Row sm="12" md="12" lg="12">
                          <Col sm="12" md="6" lg="6">
                            <InputGroup className={"no-border input-lg"}>
                                <Input
                                    className="contato-input"
                                    placeholder="Cidade/Estado"
                                    type="text"
                                    maxLength={60}
                                    onChange={(e)=> this.formParceiro.cidade = e.target.value }
                                    required
                                ></Input>
                            </InputGroup>
                          </Col>
                          <Col sm="12" md="6" lg="6">
                            <InputGroup className={"no-border input-lg"}>
                                <Input
                                    className="contato-input"
                                    placeholder="E-mail"
                                    type="text"
                                    maxLength={60}
                                    onChange={(e)=> this.formParceiro.email = e.target.value }
                                    required
                                ></Input>
                            </InputGroup>
                          </Col>
                        </Row>

                        <Row sm="12" md="12" lg="12">
                          <Col sm="12" md="6" lg="6">
                            <InputGroup className={"no-border input-lg"}>
                                <Input
                                    className="contato-input"
                                    placeholder="Celular"
                                    type="text"
                                    maxLength={15}
                                    onChange={(e)=> { 
                                      e.target.value = maskTelefone(e.target.value);
                                      this.formParceiro.celular = e.target.value; 
                                    }}
                                    required
                                ></Input>
                            </InputGroup>
                          </Col>
                          <Col sm="12" md="6" lg="6">
                            <InputGroup className={"no-border input-lg"}>
                                <Input
                                    className="contato-input"
                                    placeholder="Telefone"
                                    type="text"
                                    maxLength={15}
                                    onChange={(e)=> { 
                                      e.target.value = maskTelefone(e.target.value);
                                      this.formParceiro.telefone = e.target.value; 
                                    }}
                                    required
                                ></Input>
                            </InputGroup>
                          </Col>
                        </Row>

                        <Row sm="12" md="12" lg="12">
                          <Col sm="12" md="12" lg="12">
                            <InputGroup className={"no-border input-lg"}>
                                <Input
                                    style={{borderRadius: '20px'}}
                                    className="contato-input"
                                    placeholder="Mensagem"
                                    type="textarea"
                                    maxLength={500}
                                    onChange={(e)=> this.formParceiro.mensagem = e.target.value }
                                    required
                                ></Input>
                            </InputGroup>
                          </Col>
                        </Row>
                        
                    </Col>
                  </CardBody>
                  <div className="contato-form-actions">
                    <div className="btn-contato-fechar pointer" onClick={this.handleParceiroClose}>
                      FECHAR
                    </div>
                    <button id="btnparceiro-enviar" type="submit" style={{display: 'hidden', visibility: 'hidden'}}></button>
                    <div className="btn-contato-enviar pointer" type="submit" onClick={() => {
                      document.getElementById('btnparceiro-enviar').click();
                    }}>
                      ENVIAR
                    </div>
                  </div>
                </Form>
              </div>
            </Dialog> */}

          {/* <Dialog open={this.state.dialogTrabalheOpen} onClose={this.handleTrabalheClose} aria-labelledby="form-dialog-title">
              <div className="dialog-conteudo-trabalhe">
                <div className="dialog-conteudo-texto-trabalhe">
                Grandes oportunidades para profissionais e programas de trainee para estudantes que querem ir mais longe.
                <br />
                <br />
                Envie seu currículo em pdf!
                <br />
                </div>
                <Form id="trabalheForm" className="form" onSubmit={this.handleTrabalheSubmit}>
                  <CardBody>
                    <Col>
                        <Row sm="12" md="12" lg="12">
                          <Col sm="12" md="6" lg="6">
                            <InputGroup className={"no-border input-lg"}>
                                <Input
                                    className="contato-input"
                                    placeholder="Nome"
                                    type="text"
                                    maxLength={60}
                                    onChange={(e)=> this.formTrabalhe.nome = e.target.value }
                                    required
                                    ></Input>
                            </InputGroup>
                          </Col>
                          <Col sm="12" md="6" lg="6">
                            <InputGroup className={"no-border input-lg"}>
                                <Input
                                    className="contato-input"
                                    placeholder="E-mail"
                                    type="text"
                                    maxLength={60}
                                    onChange={(e)=> this.formTrabalhe.email = e.target.value }
                                    required
                                    ></Input>
                            </InputGroup>
                          </Col>
                        </Row>
                        <Row sm="12" md="12" lg="12">
                          <Col sm="12" md="6" lg="6">
                            <InputGroup className={"no-border input-lg"}>
                                <Input
                                    className="contato-input"
                                    placeholder="Cargo/Função de interesse"
                                    type="text"
                                    maxLength={60}
                                    onChange={(e)=> this.formTrabalhe.cargo = e.target.value }
                                    required
                                    ></Input>
                            </InputGroup>
                          </Col>
                          <Col sm="12" md="6" lg="6">
                            <InputGroup className={"no-border input-lg"}>
                                <Input
                                    className="contato-input"
                                    placeholder="Cidade/Estado"
                                    type="text"
                                    maxLength={60}
                                    onChange={(e)=> this.formTrabalhe.cidade = e.target.value }
                                    required
                                    ></Input>
                            </InputGroup>
                          </Col>
                        </Row>

                        <Row sm="12" md="12" lg="12">
                          <Col sm="12" md="6" lg="6">
                            <InputGroup className={"no-border input-lg"}>
                                <Input
                                    className="contato-input"
                                    placeholder="Celular"
                                    type="text"
                                    maxLength={15}
                                    onChange={(e)=> { 
                                      e.target.value = maskTelefone(e.target.value);
                                      this.formTrabalhe.celular = e.target.value; 
                                    }}
                                    required
                                ></Input>
                            </InputGroup>
                          </Col>
                          <Col sm="12" md="6" lg="6">
                            <InputGroup className={"no-border input-lg"}>
                                <Input
                                    className="contato-input"
                                    placeholder="Telefone"
                                    type="text"
                                    maxLength={60}
                                    onChange={(e)=> { 
                                      e.target.value = maskTelefone(e.target.value);
                                      this.formTrabalhe.telefone = e.target.value; 
                                    }}
                                ></Input>
                            </InputGroup>
                          </Col>
                        </Row>

                        <Row sm="12" md="12" lg="12">
                          <Col sm="12" md="6" lg="6">
                            <InputGroup className={"no-border input-lg"}>
                                <Input
                                    className="contato-input"
                                    placeholder="Está estudando? Qual Curso?"
                                    type="text"
                                    maxLength={60}
                                    onChange={(e)=> this.formTrabalhe.curso = e.target.value }
                                    required
                                ></Input>
                            </InputGroup>
                          </Col>
                          <Col sm="12" md="6" lg="6">
                            <InputGroup className={"no-border input-lg"}>
                              <Input
                                onChange={(e)=> { this.uploadFile(e.target.files[0]) }}
                                name="file" id="file"
                                className="inputfile"
                                type="file"
                              ></Input>
                              <Col>
                                <label htmlFor="file" className="anexo-input">
                                    <i style={{marginRight: '5px'}} className="fa fa-paperclip"></i>
                                    Anexar currículo
                                </label>
                                <br />
                                <div>
                                  <label className="file-trabalhe-label">
                                    {this.state.filename}
                                  </label>

                                </div>
                              </Col>
                            </InputGroup>
                          </Col>
                        </Row>

                        <Row sm="12" md="12" lg="12">
                          <Col sm="12" md="12" lg="12">
                            <InputGroup className={"no-border input-lg"}>
                                <Input
                                    style={{borderRadius: '20px'}}
                                    className="contato-input"
                                    placeholder="Mensagem"
                                    type="textarea"
                                    maxLength={500}
                                    onChange={(e)=> this.formTrabalhe.mensagem = e.target.value }
                                    required
                                ></Input>
                            </InputGroup>
                          </Col>
                        </Row>
                        
                    </Col>
                  </CardBody>
                  <div className="contato-form-actions">
                    <div className="btn-contato-fechar pointer" onClick={this.handleTrabalheClose}>
                      FECHAR
                    </div>
                    <button id="btntrabalho-enviar" type="submit" style={{display: 'hidden', visibility: 'hidden'}}></button>
                    <div className="btn-contato-enviar-trabalhe pointer" type="submit" onClick={() => {
                      document.getElementById('btntrabalho-enviar').click();
                    }}>
                      ENVIAR
                    </div>
                  </div>
                </Form>
              </div>
            </Dialog>
            <Alert className="alert-contato" color="success" isOpen={this.state.alertVisible}>
              Enviado com sucesso!
            </Alert> */}
        </div>
      </>
    );
  }
}

export default ContatoContatos;
