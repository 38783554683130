import React from "react";
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

function IndexNavbar() {
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [collapseOpen, setCollapseOpen] = React.useState(false);

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 99 ||
        document.body.scrollTop > 99
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 100 ||
        document.body.scrollTop < 100
      ) {
        setNavbarColor("navbar-transparent");
      }
    };
    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar className={"fixed-top " + navbarColor} expand="lg" color="info">
        <Container>
          <div className="navbar-translate">
            <NavbarBrand
              href="/index"
              id="navbar-brand"
            >
            <img
              alt="..."
              width="100%"
              className="germantech-logo"
              src={require("assets/img/logo.png")}
            ></img>
            </NavbarBrand>
            <button
              className="navbar-toggler navbar-toggler"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(!collapseOpen);
              }}
              aria-expanded={collapseOpen}
              type="button"
            >
              <span className="navbar-toggler-bar top-bar"></span>
              <span className="navbar-toggler-bar middle-bar"></span>
              <span className="navbar-toggler-bar bottom-bar"></span>
            </button>
          </div>
          <Collapse
            className="justify-content-end"
            isOpen={collapseOpen}
            navbar
          >
            <Nav navbar className="navbar-itens">
              <NavItem>
                <NavLink
                  href='/index'
                  style={{ cursor: 'pointer' }}
                >
                  <p>Página Inicial</p>
                </NavLink>
              </NavItem>
                <NavItem>
                  <NavLink
                    href='/nossa-historia'
                    style={{ cursor: 'pointer' }}
                  >
                      <p>Nossa História</p>
                  </NavLink>
                </NavItem>
              <NavItem>
                {/* <NavLink
                  style={{ cursor: 'pointer' }}
                >
                  <p>Sistemas</p>
                </NavLink> */}
                <UncontrolledDropdown nav>
                  <DropdownToggle
                    color="default"
                    href="#"
                    nav
                    onClick={e => e.preventDefault()}
                  >
                    <p>Sistemas</p>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-itens">
                    <DropdownItem to="/emissor" href='/emissor'>
                      <b>EMISSOR</b>
                    </DropdownItem>
                    <DropdownItem to="/light" href='/light'>
                      <b>LIGHT</b>
                    </DropdownItem>
                    <DropdownItem to="/manager" href='/manager'>
                      <b>MANAGER</b>
                    </DropdownItem>
                    {/* <DropdownItem to="/gourmet" href='/gourmet'>
                      <b>GOURMET</b>
                    </DropdownItem> */}
                    <DropdownItem to="/pro-vendas" onClick={() => window.open("https://provendascrm.com.br/index")}>
                      <b>PRÓ-VENDAS</b>
                    </DropdownItem>
                    <DropdownItem to="/comparativo" href='/comparativo'>
                      <b>PLANOS E RECURSOS</b>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </NavItem>
              <NavItem>
                <NavLink
                  href='/contatos'
                  style={{ cursor: 'pointer' }}
                >
                  <p>Contatos</p>
                </NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default IndexNavbar;
