import React, {Component} from "react";
import { Col } from "reactstrap";

class ManagerTitulo extends Component {

  state = {
    btnSelected: 0
  }

  render() {

    return (
      <>
        <div className="element sistemas-bloco-titulo">
            <div className="flex-row justify-content-md-center titulo-sistemas">
              <Col lg="7" md="12" className="titulo-sistemas-col">
                <h3 className="sistemas-pagina-titulo">
                  POR QUE O ERP MANAGER DA GERMAN TECH?
                </h3>
                <h4 className="sistemas-pagina-subtitulo">
                Esse poderoso software que contempla todas as ferramentas de gestão dos
principais pilares de uma empresa: Financeiro, Vendas e Estoque. 
Multiplique suas vendas, melhore seus controles e obtenha
o máximo de economia.
                </h4>
              </Col>
              <Col lg="5" md="12" className="titulo-sistemas-col2">
                <div className="sistemas-balao">
                    <div className="sistemas-balao-titulo">O ERP MANAGER INCLUI</div>
                    <hr className="solid hr-sistemas"></hr>
                    <span className="sistemas-balao-subtitulo">Controle de Contas a Pagar e a Receber</span>
                    <br />
                    <div className="sistemas-balao-conteudo">
Reduz perdas e apresenta uma melhora de
até 30% em suas receitas já nos primeiros
90 dias de uso.</div>
                    <br />
                    <span className="sistemas-balao-subtitulo">Controle de Crediário</span>
                    <br />
                    <div className="sistemas-balao-conteudo">
Com esta poderosa ferramenta, poderá ter
total controle sobre suas vendas à prazo.
Reduza a inadimplência, negocie com seus
clientes e obtenha melhora de até 35% nos
seus resultados já no primeiro mês de uso.</div>
                    <br />
                    <span className="sistemas-balao-subtitulo">Controle de Movimentações
Bancárias</span>
                    <br />
                    <div className="sistemas-balao-conteudo">
O controle bancário é um poderoso instrumento
de gestão, pelo qual o empresário pode verificar
com rapidez e eficiência a disponibilidade de seus
recursos. Contribui, ainda, para a tomada decisões
acertadas e melhoria do fluxo financeiro.</div>
                    <br />
                    <div className="sistemas-balao-contato" onClick={() => window.open("https://wa.me/554530569085")}>
                    {/* <img
                        alt="wpp-fab"
                        className="sistemas-balao-wpp"
                        src={require("assets/img/wpp.png")}
                    ></img> */}
                        <span className="sistemas-balao-contato-subtitulo">FALE AGORA COM UM CONSULTOR</span>
                        <br />
                        <div className="sistemas-balao-conteudo2">Entre em contato, tire suas dúvidas com uma 
                        equipe especializada e faça um teste
                        sem compromisso.</div>
                    </div>
                </div>
              </Col>
            </div>
        </div>
      </>
    );
  }
}

export default ManagerTitulo;