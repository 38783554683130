import React, {Component} from "react";
import { Row, Col } from "reactstrap";

class ManagerRecursos extends Component {

  state = {
    btnSelected: 0
  }

  render() {

    return (
      <>
        <div className="element recursos-manager">
          <Col className="justify-content-md-center">
            <Row lg="12" md="12">
              <h3 className="recursos-top-titulo">
                PRINCIPAIS RECURSOS
              </h3>
            </Row>

            <Row lg="12" md="12" className="recursos-row">
              <Col lg="3" md="3">
                <img
                  alt="manager-beneficios-1"
                  className="beneficios-icons"
                  src={require("assets/img/manager-r-1.png")}
                ></img>
                <br />
                <span className="recursos-titulo">
                Controle de Contas a Pagar
e a Receber
                </span><br />
                <span className="recursos-subtitulo">
Tenha controle financeiro completo e permita que sua
empresa se torne mais eficiente e ganhe produtividade
no seu dia a dia.
                </span>
              </Col>
              <Col lg="3" md="3">
                <img
                  alt="manager-beneficios-1"
                  className="beneficios-icons"
                  src={require("assets/img/manager-r-2.png")}
                ></img>
                <br />
                <span className="recursos-titulo">
                Controle de Movimentações
Bancárias
                </span><br />
                <span className="recursos-subtitulo">
Verifique com rapidez e facilidade todos os registros
 de movimentações de suas contas. Tire
extratos e faça uma conferência muito mais eficaz.
                </span>
              </Col>
              <Col lg="3" md="3">
                <img
                  alt="manager-beneficios-1"
                  className="beneficios-icons"
                  src={require("assets/img/manager-r-3.png")}
                ></img>
                <br />
                <span className="recursos-titulo">
                Emissão de Duplicatas, Notas
Promisórias e Recibos
                </span><br />
                <span className="recursos-subtitulo">
Realizar a emissão de vários documentos gerenciais
tais como, carnês, boletos, duplicatas, notas
promissórias e muitos outros.
                </span>
              </Col>
              <Col lg="3" md="3">
                <img
                  alt="manager-beneficios-1"
                  className="beneficios-icons"
                  src={require("assets/img/manager-r-4.png")}
                ></img>
                <br />
                <span className="recursos-titulo">
                Controle de Cheques Emitidos
e Recebidos
                </span><br />
                <span className="recursos-subtitulo">
Simples e prático, você pode contar com total
rastreio de modo rápido de cada documento.
                </span>
              </Col>
            </Row>
            <Row lg="12" md="12" className="recursos-row">
              <Col lg="3" md="3">
                <img
                  alt="manager-beneficios-1"
                  className="beneficios-icons"
                  src={require("assets/img/manager-r-5.png")}
                ></img>
                <br />
                <span className="recursos-titulo">
                Suporte Especializado
                </span><br />
                <span className="recursos-subtitulo">
                Atendimento de suporte segunda-feira a sexta-feira das 8h às 18h e nos sábados das 8h às 12h.
                    Atendimento de plantão todos os dias até às 22 horas, incluindo sábados, domingos e feriados!
                </span>
              </Col>
              <Col lg="3" md="3">
                <img
                  alt="manager-beneficios-1"
                  className="beneficios-icons"
                  src={require("assets/img/manager-r-6.png")}
                ></img>
                <br />
                <span className="recursos-titulo">
                Controle de Crediário
                </span><br />
                <span className="recursos-subtitulo">
Automatize o processo e faça consultas
rápidas de pagamentos dos clientes e
muito mais.
                </span>
              </Col>
              <Col lg="3" md="3">
                <img
                  alt="manager-beneficios-1"
                  className="beneficios-icons"
                  src={require("assets/img/manager-r-7.png")}
                ></img>
                <br />
                <span className="recursos-titulo">
                Dashboard Interativo
                </span><br />
                <span className="recursos-subtitulo">
Tenha acesso a um painel informativo com
 visualização rápida dos principais
indicadores de desempenho relevantes.
                </span>
              </Col>
              <Col lg="3" md="3">
                <img
                  alt="manager-beneficios-1"
                  className="beneficios-icons"
                  src={require("assets/img/manager-r-8.png")}
                ></img>
                <br />
                <span className="recursos-titulo">
                + de 70 Relatórios Gerenciais
Incluindo DRE
                </span><br />
                <span className="recursos-subtitulo">
Além de criar relatórios de gestão de todos os tipos,
você pode personalizar tudo conforme suas necessidades.
                </span>
              </Col>
            </Row>
          </Col>
        </div>
      </>
    );
  }
}

export default ManagerRecursos;