/*eslint-disable*/
import React from "react";
import { Row, Col } from "reactstrap";

function Footer() {
  return (
    <footer className="footer" style={{ backgroundColor: '#1e2129'}}>
        <div style={{ paddingTop: 30}}>
          <Row>
            <Col sm="12" md="4" lg="4">
              <img
                alt="germantech-logo-em-branco"
                className="germantech-logo-footer"
                src={require("assets/img/logo-branca.png")}
              ></img>
              <br/>
            </Col>
            <Col className="footer-contato-div" sm="12" md="4" lg="4">
              <div className="footer-title">
                Contato
              </div>

              <Row className="footer-subtitles" style={{ cursor: 'pointer'}} onClick={() => window.open("https://goo.gl/maps/QrDsmhEgtfLwu3cC8")}>
                <a
                    className="icon-contato"
                    style={{ paddingLeft: '7px' }}
                    target="_blank"
                  >
                    <i className="fa fa-map-marker-alt footer-map-icon"></i>
                </a>
                <div className="footer-subtitles footer-endereco">
                  Rua Santos Dumont, 2005 <br/>Centro - Toledo PR
                </div>
              </Row>

              <Row className="footer-subtitles">
                <a
                    className="icon-contato"
                    target="_blank"
                  >
                    <i className="fas fa-phone-square fa-fw"></i>
                </a>
                <div className="footer-subtitles">
                  (45) 3056-9029
                </div>
              </Row>
              <Row className="footer-subtitles" style={{ cursor: 'pointer'}} onClick={() => window.open("https://wa.me/554530569085")}>
                <a
                    className="icon-contato"
                    target="_blank"
                  >
                    <i className="fab fa-whatsapp fa-fw"></i>
                </a>
                <div className="footer-subtitles">
                  (45) 99849-8928
                </div>
              </Row>
            </Col>
            <Col sm="12" md="4" lg="4">
              <div className="footer-title">
                Social
              </div>
              <Row className="footer-subtitles" style={{ cursor: 'pointer'}} onClick={() => window.open("https://www.facebook.com/germantechsistemas/")}>
                <a
                    className="social-media"
                    href="https://www.facebook.com/germantechsistemas/"
                    target="_blank"
                  >
                    <i className="fab fa-facebook-f fa-fw"></i>
                </a>
                <div className="footer-subtitles">
                  German Tech Sistemas
                </div>
              </Row>
              <Row className="footer-subtitles" style={{ cursor: 'pointer'}} onClick={() => window.open("https://www.instagram.com/sistemasgermantech/")}>
                <a
                    className="social-media"
                    href="https://www.instagram.com/sistemasgermantech/"
                    target="_blank"
                  >
                    <i className="fab fa-instagram fa-fw"></i>
                </a>
                <div className="footer-subtitles">
                  German Tech Sistemas
                </div>
              </Row>
              <Row className="footer-subtitles" style={{ cursor: 'pointer'}} onClick={() => window.open("https://www.youtube.com/channel/UC8WCfE440j9nQWRDeLLgDEA")}>
                <a
                    className="social-media"
                    href="https://www.youtube.com/channel/UC8WCfE440j9nQWRDeLLgDEA"
                    target="_blank"
                  >
                    <i className="fab fa-youtube fa-fw"></i>
                </a>
                <div className="footer-subtitles">
                  German Tech Sistemas
                </div>
              </Row>
            </Col>
          </Row>
        </div>
        <div style={{color: '#fff', paddingTop: '35px'}} className="copyright" id="copyright">
          © {new Date().getFullYear()}, Desenvolvido por {" "}
          <a
            style={{color: '#f1f1f1', fontSize: '13px'}}
            href="http://germantech.com.br/site/"
            target="_blank"
          >
            German Tech Sistemas
          </a>
        </div>
    </footer>
  );
}

export default Footer;
