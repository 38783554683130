/*eslint-disable*/
import React from "react";
import {isMobile} from 'react-device-detect';

function EmissorHeader() {
  return (
    <>
      <div style={{marginTop: 70}}>
        <div className="">
        {
            isMobile ? 
              window.innerWidth < 600 ? 
              (
                <img
                    alt="header-home"
                    src={require("assets/img/capa-emissor-mobile.png")}
                ></img>
              ) : (
                <img
                    alt="header-home"
                    src={require("assets/img/capa-emissor-1024.png")}
                ></img>
              )
            : (
              <img
                  alt="header-home"
                  src={require("assets/img/capa-emissor.png")}
              ></img>
            )
          }
        </div>
      </div>
    </>
  );
}

export default EmissorHeader;