import React, {Component} from "react";
import { Col } from "reactstrap";

class EmissorTitulo extends Component {

  state = {
    btnSelected: 0
  }

  render() {

    return (
      <>
        <div className="element sistemas-bloco-titulo">
            <div className="flex-row justify-content-md-center titulo-sistemas">
              <Col lg="7" md="12" className="titulo-sistemas-col">
                <h3 className="sistemas-pagina-titulo">
                  POR QUE O SISTEMA EMISSOR DA GERMAN TECH?
                </h3>
                <h4 className="sistemas-pagina-subtitulo">
                Esse sistema é ideal para pequenas empresas que desejam emitir suas notas fiscais
                de forma rápida, prática e sem complicações. Emita sua nota fiscal em até três
                cliques, inserindo o produto, a forma de pagamento e confirmando a venda.
                </h4>
              </Col>
              <Col lg="5" md="12" className="titulo-sistemas-col2">
                <div className="sistemas-balao">
                    <div className="sistemas-balao-titulo">O SISTEMA EMISSOR INCLUI</div>
                    <hr className="solid hr-sistemas"></hr>
                    <span className="sistemas-balao-subtitulo">Nota Fiscal</span>
                    <br />
                    <div className="sistemas-balao-conteudo">Emita suas notas de produto
                    (NF-e ou NFC-e) e/ou serviços (NFS-e) de
                    forma rápida e fácil.</div>
                    <br />
                    <span className="sistemas-balao-subtitulo">Certificado Digital</span>
                    <br />
                    <div className="sistemas-balao-conteudo">Para a assinatura eletrônica das notas
                    fiscais, é necesário que a empresa possua
                    o certificado digital e-CNPJ válido. Nosso
                    sistema é compatível tanto com o modelo
                    A1 quanto com o A3.</div>
                    <br />
                    <span className="sistemas-balao-subtitulo">Padronização Automática</span>
                    <br />
                    <div className="sistemas-balao-conteudo">Ferramenta exclusiva que faz o
                    preenchimento automático de todas as
                    regras tributárias do NCM do produto de
                    acordo com o regime tributário da
                    empresa, evitando o pagamento de
                    impostos indevidos.</div>
                    <br />
                    <div className="sistemas-balao-contato" onClick={() => window.open("https://wa.me/554530569085")}>
                    {/* <img
                        alt="wpp-fab"
                        className="sistemas-balao-wpp"
                        src={require("assets/img/wpp.png")}
                    ></img> */}
                        <span className="sistemas-balao-contato-subtitulo">FALE AGORA COM UM CONSULTOR</span>
                        <br />
                        <div className="sistemas-balao-conteudo2">Entre em contato, tire suas dúvidas com uma 
                        equipe especializada e faça um teste
                        sem compromisso.</div>
                    </div>
                </div>
              </Col>
            </div>
        </div>
      </>
    );
  }
}

export default EmissorTitulo;