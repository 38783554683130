import React, {Component} from "react";
import { Col } from "reactstrap";

class LightTitulo extends Component {

  state = {
    btnSelected: 0
  }

  render() {

    return (
      <>
        <div className="element sistemas-bloco-titulo">
            <div className="flex-row justify-content-md-center titulo-sistemas">
              <Col lg="7" md="12" className="titulo-sistemas-col">
                <h3 className="sistemas-pagina-titulo">
                  POR QUE O SISTEMA LIGHT DA GERMAN TECH?
                </h3>
                <h4 className="sistemas-pagina-subtitulo">
                Esse sistema é ideal para administrar e gerenciar seu estoque, caixa, fornecedores
e suas vendas com eficiência, objetividade e praticidade. Tenha visão sobre os
pilares que sustentam sua empresa.
                </h4>
              </Col>
              <Col lg="5" md="12" className="titulo-sistemas-col2">
                <div className="sistemas-balao">
                    <div className="sistemas-balao-titulo">O SISTEMA LIGHT INCLUI</div>
                    <hr className="solid hr-sistemas"></hr>
                    <span className="sistemas-balao-subtitulo">Ponto de Venda (PDV)</span>
                    <br />
                    <div className="sistemas-balao-conteudo">
Fazemos a venda em até três clicks!
É ágil e intuitivo de usar e está integrado a
diversos equipamentos como impressoras,
leitor de código de barras, balanças e gaveta
de dinheiro.</div>
                    <br />
                    <span className="sistemas-balao-subtitulo">Controle de Caixa e Estoque</span>
                    <br />
                    <div className="sistemas-balao-conteudo">
Cadastra seus produtos, registra as
movimentações realizadas, gerencia o caixa,
gerencia a venda, cuida do fluxo de caixa
além de muitas outras funções através de
uma interface extremamente intuitiva.</div>
                    <br />
                    <span className="sistemas-balao-subtitulo">Cadastro Automático de
Produtos</span>
                    <br />
                    <div className="sistemas-balao-conteudo">
Ganhe tempo no lançamento e cadastro de
seus produtos de forma simples e rápida.
Tenha acesso às informações do produto e
altere de forma prática.</div>
                    <br />
                    <div className="sistemas-balao-contato" onClick={() => window.open("https://wa.me/554530569085")}>
                    {/* <img
                        alt="wpp-fab"
                        className="sistemas-balao-wpp"
                        src={require("assets/img/wpp.png")}
                    ></img> */}
                        <span className="sistemas-balao-contato-subtitulo">FALE AGORA COM UM CONSULTOR</span>
                        <br />
                        <div className="sistemas-balao-conteudo2">Entre em contato, tire suas dúvidas com uma 
                        equipe especializada e faça um teste
                        sem compromisso.</div>
                    </div>
                </div>
              </Col>
            </div>
        </div>
      </>
    );
  }
}

export default LightTitulo;