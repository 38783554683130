import React, {Component} from "react";
import { Row, Col } from "reactstrap";

class EmissorRecursos extends Component {

  state = {
    btnSelected: 0
  }

  render() {

    return (
      <>
        <div className="element recursos-emissor">
          <Col className="justify-content-md-center">
            <Row lg="12" md="12">
              <h3 className="recursos-top-titulo">
                PRINCIPAIS RECURSOS
              </h3>
            </Row>

            <Row lg="12" md="12" className="recursos-row">
              <Col lg="3" md="3">
                <img
                  alt="emissor-beneficios-1"
                  className="beneficios-icons"
                  src={require("assets/img/emissor-r-1.png")}
                ></img>
                <br />
                <span className="recursos-titulo">
                  Ponto de Venda (PDV)
                </span><br />
                <span className="recursos-subtitulo">
                Diminui a quantidade de equipamentos, custos de
                manutenção, entre outras demandas.
                Emissão de Documentos Fiscais NFC-e, Nf-e, CT-e,
                NFs-e e SAT (ilimitada).
                </span>
              </Col>
              <Col lg="3" md="3">
                <img
                  alt="emissor-beneficios-1"
                  className="beneficios-icons"
                  src={require("assets/img/emissor-r-2.png")}
                ></img>
                <br />
                <span className="recursos-titulo">
                Nota Fiscal de Consumidor
                </span><br />
                <span className="recursos-subtitulo">
Emissão de NFC-e através da tela de venda ou
utilizando o recurso PDV para agilizar o atendimento.
Permite integração com outros sistemas.
                </span>
              </Col>
              <Col lg="3" md="3">
                <img
                  alt="emissor-beneficios-1"
                  className="beneficios-icons"
                  src={require("assets/img/emissor-r-3.png")}
                ></img>
                <br />
                <span className="recursos-titulo">
                Nota Fiscal de Serviço
                </span><br />
                <span className="recursos-subtitulo">
Emissão da nota fiscal de prestação de serviço
com comunicação direta com o site da prefeitura.
Homologado em mais de 1500 cidades.
                </span>
              </Col>
              <Col lg="3" md="3">
                <img
                  alt="emissor-beneficios-1"
                  className="beneficios-icons"
                  src={require("assets/img/emissor-r-4.png")}
                ></img>
                <br />
                <span className="recursos-titulo">
                Consulta de CNPJ
                </span><br />
                <span className="recursos-subtitulo">
Digitando o CNPJ da empresa, o sistema faz a
importação automática de todos os dados de
forma imediata pra você.
                </span>
              </Col>
            </Row>
            <Row lg="12" md="12" className="recursos-row">
              <Col lg="3" md="3">
                <img
                  alt="emissor-beneficios-1"
                  className="beneficios-icons"
                  src={require("assets/img/emissor-r-5.png")}
                ></img>
                <br />
                <span className="recursos-titulo">
                Suporte Especializado
                </span><br />
                <span className="recursos-subtitulo">
                Atendimento de suporte segunda-feira a sexta-feira das 8h às 18h e nos sábados das 8h às 12h.
                    Atendimento de plantão todos os dias até às 22 horas, incluindo sábados, domingos e feriados!
                </span>
              </Col>
              <Col lg="3" md="3">
                <img
                  alt="emissor-beneficios-1"
                  className="beneficios-icons"
                  src={require("assets/img/emissor-r-6.png")}
                ></img>
                <br />
                <span className="recursos-titulo">
                Cadastro de Produtos, Clientes
e Fornecedores
                </span><br />
                <span className="recursos-subtitulo">
Fazer o cadastro de produtos, clientes e fornecedores
aumenta sua carteira de clientes e ainda traz 
inúmeros outros benefícios para a sua empresa!
                </span>
              </Col>
              <Col lg="3" md="3">
                <img
                  alt="emissor-beneficios-1"
                  className="beneficios-icons"
                  src={require("assets/img/emissor-r-7.png")}
                ></img>
                <br />
                <span className="recursos-titulo">
                Compatível com Certificados
Modelos A1 e A3
                </span><br />
                <span className="recursos-subtitulo">
Certifiado digital, que comprova a autenticidade
do processo ou seja, que foi realmente a sua
empresa que emitiu a nota fiscal.
                </span>
              </Col>
              <Col lg="3" md="3">
                <img
                  alt="emissor-beneficios-1"
                  className="beneficios-icons"
                  src={require("assets/img/emissor-r-8.png")}
                ></img>
                <br />
                <span className="recursos-titulo">
                Backup Automático em Nuvem
                </span><br />
                <span className="recursos-subtitulo">
Desfrute de tranquilidade com o data center
de armazenamento seguro, equipado
com as mais recentes tecnologias de
prevenção de desastres.
                </span>
              </Col>
            </Row>
          </Col>
        </div>
      </>
    );
  }
}

export default EmissorRecursos;