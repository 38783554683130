import React, {Component} from "react";
import { Row, Col } from "reactstrap";

class LightRecursos extends Component {

  state = {
    btnSelected: 0
  }

  render() {

    return (
      <>
        <div className="element recursos-light">
          <Col className="justify-content-md-center">
            <Row lg="12" md="12">
              <h3 className="recursos-top-titulo">
                PRINCIPAIS RECURSOS
              </h3>
            </Row>

            <Row lg="12" md="12" className="recursos-row">
              <Col lg="3" md="3">
                <img
                  alt="light-beneficios-1"
                  className="beneficios-icons"
                  src={require("assets/img/light-r-1.png")}
                ></img>
                <br />
                <span className="recursos-titulo">
                Controle de Caixa e Estoque
                </span><br />
                <span className="recursos-subtitulo">
Conheça as movimentações de entradas e saídas de
mercadorias, geração de caixa e o saldo do
caixa por período.
                </span>
              </Col>
              <Col lg="3" md="3">
                <img
                  alt="light-beneficios-1"
                  className="beneficios-icons"
                  src={require("assets/img/light-r-2.png")}
                ></img>
                <br />
                <span className="recursos-titulo">
                Emissão de Orçamentos,
Pedidos e Ordens
                </span><br />
                <span className="recursos-subtitulo">
Emita de forma rápida e descomplicada os
seus orçamentos, pedidos e ordens.
                </span>
              </Col>
              <Col lg="3" md="3">
                <img
                  alt="light-beneficios-1"
                  className="beneficios-icons"
                  src={require("assets/img/light-r-3.png")}
                ></img>
                <br />
                <span className="recursos-titulo">
                Importação de Notas de Compra
                </span><br />
                <span className="recursos-subtitulo">
Evite erros na sua nota fiscal de importação e não
perca dinheiro com multas tributárias.
                </span>
              </Col>
              <Col lg="3" md="3">
                <img
                  alt="light-beneficios-1"
                  className="beneficios-icons"
                  src={require("assets/img/light-r-4.png")}
                ></img>
                <br />
                <span className="recursos-titulo">
                Controle de Trocas,
Devoluções e Condicionais
                </span><br />
                <span className="recursos-subtitulo">
Manter atualizado o estoque de seus produtos significa
apurar com excelência seu movimento de entrada e saída.
                </span>
              </Col>
            </Row>
            <Row lg="12" md="12" className="recursos-row">
              <Col lg="3" md="3">
                <img
                  alt="light-beneficios-1"
                  className="beneficios-icons"
                  src={require("assets/img/light-r-5.png")}
                ></img>
                <br />
                <span className="recursos-titulo">
                Suporte Especializado
                </span><br />
                <span className="recursos-subtitulo">
                Atendimento de suporte segunda-feira a sexta-feira das 8h às 18h e nos sábados das 8h às 12h.
                    Atendimento de plantão todos os dias até às 22 horas, incluindo sábados, domingos e feriados!
                </span>
              </Col>
              <Col lg="3" md="3">
                <img
                  alt="light-beneficios-1"
                  className="beneficios-icons"
                  src={require("assets/img/light-r-6.png")}
                ></img>
                <br />
                <span className="recursos-titulo">
                Cadastro Automático de Produtos
                </span><br />
                <span className="recursos-subtitulo">
Automatização no processo de cadastro de
produtos, economizando até 98% do tempo.
                </span>
              </Col>
              <Col lg="3" md="3">
                <img
                  alt="light-beneficios-1"
                  className="beneficios-icons"
                  src={require("assets/img/light-r-7.png")}
                ></img>
                <br />
                <span className="recursos-titulo">
                Parametrização Automática de Produtos
                </span><br />
                <span className="recursos-subtitulo">
Feramenta exclusiva elaborada por especialistas da área
que possibilita a redução da carga tributária entre
outras funções.
                </span>
              </Col>
              <Col lg="3" md="3">
                <img
                  alt="light-beneficios-1"
                  className="beneficios-icons"
                  src={require("assets/img/light-r-8.png")}
                ></img>
                <br />
                <span className="recursos-titulo">
                Mais de 60 Relatórios Gerenciais
                </span><br />
                <span className="recursos-subtitulo">
Além de criar relatórios de gestão de todos os
tipos, você pode personalizar tudo conforme suas
necessidades.

                </span>
              </Col>
            </Row>
          </Col>
        </div>
      </>
    );
  }
}

export default LightRecursos;