import React, {Component} from "react";
import { Row, Col } from "reactstrap";

class LightBeneficios extends Component {

  state = {
    btnSelected: 0
  }

  render() {

    return (
      <>
        <div className="element beneficios-emissor">
          <Col className="justify-content-md-center">
            <Row lg="12" md="12">
              <h3 className="beneficios-titulo">
                BENEFÍCIOS
              </h3>
            </Row>

            <Row lg="12" md="12" className="beneficios-row">
              <Col lg="4" md="4">
                <img
                  alt="light-beneficios-1"
                  className="beneficios-icons"
                  src={require("assets/img/light-b-1.png")}
                ></img>
                <br />
                <span>
                Ter total controle de como e para quais
finalidades o seu dinheiro é utilizado.
                </span>
              </Col>
              <Col lg="4" md="4">
                <img
                  alt="light-beneficios-1"
                  className="beneficios-icons"
                  src={require("assets/img/light-b-2.png")}
                ></img>
                <br />
                <span>
                Acompanhar e avaliar a produtividade
dos funcionários.
                </span>
              </Col>
              <Col lg="4" md="4">
                <img
                  alt="light-beneficios-1"
                  className="beneficios-icons"
                  src={require("assets/img/light-b-3.png")}
                ></img>
                <br />
                <span>
                Saber quais produtos, marcas ou
departamentos da empresa são
mais rentáveis.
                </span>
              </Col>
            </Row>
            <Row lg="12" md="12" className="beneficios-row">
              <Col lg="4" md="4">
                <img
                  alt="light-beneficios-1"
                  className="beneficios-icons"
                  src={require("assets/img/light-b-4.png")}
                ></img>
                <br />
                <span>
                Facilitar a emissão de notas fiscais, com
simplicidade, qualidade e rapidez.
                </span>
              </Col>
              <Col lg="4" md="4">
                <img
                  alt="light-beneficios-1"
                  className="beneficios-icons"
                  src={require("assets/img/light-b-5.png")}
                ></img>
                <br />
                <span>
                Redução de custos com pagamento
de impostos indevidos.
                </span>
              </Col>
              <Col lg="4" md="4">
                <img
                  alt="light-beneficios-1"
                  className="beneficios-icons"
                  src={require("assets/img/light-b-6.png")}
                ></img>
                <br />
                <span>
                Automatizar todo o processo
de execução de vendas.
                </span>
              </Col>
            </Row>

          </Col>
          
        </div>
      </>
    );
  }
}

export default LightBeneficios;