import React, {Component} from "react";
import { Row, Col } from "reactstrap";

class ManagerBeneficios extends Component {

  state = {
    btnSelected: 0
  }

  render() {

    return (
      <>
        <div className="element beneficios-emissor">
          <Col className="justify-content-md-center">
            <Row lg="12" md="12">
              <h3 className="beneficios-titulo">
                BENEFÍCIOS
              </h3>
            </Row>

            <Row lg="12" md="12" className="beneficios-row">
              <Col lg="4" md="4">
                <img
                  alt="manager-beneficios-1"
                  className="beneficios-icons"
                  src={require("assets/img/manager-b-1.png")}
                ></img>
                <br />
                <span>
                Diminuir erros administrativos que podem ser
facilmente cometidos por não ter uma visão
total das áreas internas da sua empresa.
                </span>
              </Col>
              <Col lg="4" md="4">
                <img
                  alt="manager-beneficios-1"
                  className="beneficios-icons"
                  src={require("assets/img/manager-b-2.png")}
                ></img>
                <br />
                <span>
                Detectar falhas nos processos e
determinar as causas para os
problemas de sua empresa.
                </span>
              </Col>
              <Col lg="4" md="4">
                <img
                  alt="manager-beneficios-1"
                  className="beneficios-icons"
                  src={require("assets/img/manager-b-3.png")}
                ></img>
                <br />
                <span>
                Melhorar a tomada de decisões,
evita perdas e redução de
rentabilidade e lucratividade.
                </span>
              </Col>
            </Row>
            <Row lg="12" md="12" className="beneficios-row">
              <Col lg="4" md="4">
                <img
                  alt="manager-beneficios-1"
                  className="beneficios-icons"
                  src={require("assets/img/manager-b-4.png")}
                ></img>
                <br />
                <span>
                Automatização dos processos.
                </span>
              </Col>
              <Col lg="4" md="4">
                <img
                  alt="manager-beneficios-1"
                  className="beneficios-icons"
                  src={require("assets/img/manager-b-5.png")}
                ></img>
                <br />
                <span>
                Eliminação de controles paralelos
e planilhas eletrônicas.
                </span>
              </Col>
              <Col lg="4" md="4">
                <img
                  alt="manager-beneficios-1"
                  className="beneficios-icons"
                  src={require("assets/img/manager-b-6.png")}
                ></img>
                <br />
                <span>
                Aumento da produtividade.
                </span>
              </Col>
            </Row>

          </Col>
          
        </div>
      </>
    );
  }
}

export default ManagerBeneficios;