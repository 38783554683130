import React, {Component} from "react";
import YouTube from 'react-youtube';
import {
  Alert,
  Row,
  Button,
  CardBody,
  Form,
  Input,
  InputGroup,
  Col
} from "reactstrap";
import sendEmailContato from '../../helpers/sendEmail';
import maskTelefone from '../../helpers/masks';

class Conheca extends Component {

  state = {
    btnSelected: 0,
    alertVisible: false
  }

  form = {
    nome: '',
    contato: '',
    empresa: '',
    pagina: 'HOMEPAGE'
  }

  handleSubmit = (e) => {
    e.preventDefault();
    sendEmailContato(this.form);
    this.resetForm();
    document.getElementById("form").reset();
    this.setState({alertVisible: true});
    setTimeout(() => {
      this.setState({alertVisible: false});
    }, 2000)
  }

  resetForm = () => {
    this.form = {
      nome: '',
      contato: '',
      empresa: '',
      pagina: 'HOMEPAGE'
    }
  }


  render() {
    const opts = {
        height: '100%',
        width: '100%',
        playerVars: {
          autoplay: 0
        }
      };

    return (
      <>
        <div className="section element">
            <div className="flex-row justify-content-md-center conheca">
              <Col lg="8" md="12" sm="12">
                <Row lg="12" md="12" sm="12">
                    <YouTube
                        className="youtube-iframe"
                        videoId="MRqhRWYol30"
                        opts={opts}
                        style={{display: 'block'}}
                    />
                    <Col className="conheca-col">
                        <span className="conheca-titulo">
                            Conheça a German Tech
                        </span><br /><br />
                        <span className="conheca-subtitulo">
                            Uma empresa com referência tecnológica em todo âmbito nacional.
                            Transformamos tecnologia em resultados!
                        </span>
                    </Col>
                </Row>
                <Col className="conheca-block2">
                  <span className="conheca-titulo2">GERMAN TECH SISTEMAS</span><br /><br />
                  <span className="conheca-subtitulo2">
                  Estamos em contínuo treinamento e aperfeiçoamento. 
                  <br />Buscamos inovações no Brasil e no exterior para melhor atender
                  <br />as necessidades de nossos clientes.
                  </span>
                </Col>
              </Col>
              <Col lg="4" md="12" sm="12">
                <Col className="home-col-form">
                  <span className="home-span-form">SOLICITE O CONTATO DE UM CONSULTOR</span>
                <Form id="form" className="form" onSubmit={this.handleSubmit}>
                    <CardBody>
                    <Row>
                        <Col sm="12" md="12" lg="12">
                        <InputGroup
                        className={
                            "no-border input-lg"
                        }
                        >
                            <Input
                                className="trabalhe-input"
                                placeholder="Seu nome"
                                type="text"
                                maxLength={60}
                                onChange={(e)=> this.form.nome = e.target.value }
                                required
                            ></Input>
                        </InputGroup>
                        <InputGroup
                        className={
                          "no-border input-lg"
                        }
                        >
                            <Input
                                className="trabalhe-input"
                                placeholder="Contato"
                                maxLength={15}
                                onChange={(e)=> {
                                  e.target.value = maskTelefone(e.target.value);
                                  this.form.contato = e.target.value;
                                }}
                                required
                                type="text"
                            ></Input>
                        </InputGroup>

                        <InputGroup
                        className={
                            "no-border input-lg"
                        }
                        >
                            <Input
                                className="trabalhe-input"
                                placeholder="Empresa"
                                maxLength={60}
                                onChange={(e)=> this.form.empresa = e.target.value }
                                type="text"
                            ></Input>
                        </InputGroup>
                        </Col>
                        
                    </Row>
                    </CardBody>
                        <Button
                            className="btn-form-home"
                            color="info"
                            type='submit'
                            size="lg"
                        >
                            ENVIAR
                        </Button>
                    </Form>
                    <div className="home-text-div">
                      <span className="home-text-form">
                      Preencha suas informações e receba o
                      contato de um consultor especializado.
                      Com esta opção você também tem
                      acesso a opções exclusivas.<br />
                      Fale agora com um consultor.
                      </span><br />
                    </div>
                    <div>
                      <span className="home-text2-form pointer" onClick={() => window.location.href="/contatos"}>CONTATOS</span>
                    </div>
                </Col>
              </Col>
            </div>
            <Alert className="alert-contato" color="success" isOpen={this.state.alertVisible}>
              Enviado com sucesso!
            </Alert>
        </div>
      </>
    );
  }
}

export default Conheca;