export default function sendEmailContato(form) {
    try {
        window.Email.send({
            Host : "mail.germantech.com.br",
            Username : "contato@germantech.com.br",
            Password : "germantech,123",
            To : `contato@germantech.com.br`,
            From : "contato@germantech.com.br",
            Subject : "Nova mensagem recebida pelo Site: CONTATO",
            Body : `Novo contato de consultor enviado pelo site: germantech.com.br<br>
                    Contato enviado pela página: ${form.pagina}<br>
                    <b>Nome</b>: ${form.nome ? form.nome : ''}<br>
                    <b>Contato</b>: ${form.contato ? form.contato : ''}<br>
                    <b>Empresa</b>: ${form.empresa ? form.empresa : ''}<br>
                `,
            callback: function done(message) { 
                console.log(message)
            }
        }).then(
            console.log('')
          );
    } catch (e) {
        console.log(e)
    }
}

export function sendEmailParceiro(form) {
    try {
        window.Email.send({
            Host : "mail.germantech.com.br",
            Username : "contato@germantech.com.br",
            Password : "germantech,123",
            To : `contato@germantech.com.br`,
            From : "contato@germantech.com.br",
            Subject : "Nova mensagem recebida pelo Site: PARCEIRO",
            Body : `Novo contato de interesse de parceiro pelo site: germantech.com.br<br>
                    <b>Nome</b>: ${form.nome ? form.nome : ''}<br>
                    <b>Cidade/Estado</b>: ${form.cidade ? form.cidade : ''}<br>
                    <b>E-mail</b>: ${form.email ? form.email : ''}<br>
                    <b>Celular</b>: ${form.celular ? form.celular : ''}<br>
                    <b>Telefone</b>: ${form.telefone ? form.telefone : ''}<br>
                    <b>Mensagem</b>: ${form.mensagem ? form.mensagem : ''}<br>
                `,
            callback: function done(message) { 
                console.log(message)
            }
        }).then(
            console.log('')
          );
    } catch (e) {
        console.log(e)
    }
}


export function sendEmailTrabalhe(form) {
    try {
        window.Email.send({
            Host : "mail.germantech.com.br",
            Username : "contato@germantech.com.br",
            Password : "germantech,123",
            To : `contato@germantech.com.br`,
            From : "contato@germantech.com.br",
            Subject : "Nova mensagem recebida pelo Site: CURRICULO",
            Body : `Novo contato de interesse de trabalho pelo site: germantech.com.br<br>
                    <b>Nome</b>: ${form.nome ? form.nome : ''}<br>
                    <b>Cidade/Estado</b>: ${form.cidade ? form.cidade : ''}<br>
                    <b>E-mail</b>: ${form.email ? form.email : ''}<br>
                    <b>Celular</b>: ${form.celular ? form.celular : ''}<br>
                    <b>Telefone</b>: ${form.telefone ? form.telefone : ''}<br>
                    <b>Cursando</b>: ${form.curso ? form.curso : ''}<br>
                    <b>Cargo/Função de interesse</b>: ${form.cargo ? form.cargo : ''}<br>
                    <b>Mensagem</b>: ${form.mensagem ? form.mensagem : ''}<br>
                `,
            Attachments : [
                {
                    name : `Currículo de ${form.nome}.${form.extensao}`,
                    data : form.curriculo
                }],
            callback: function done(message) { 
                console.log(message)
            }
        }).then(
            console.log('')
          );
    } catch (e) {
        console.log(e)
    }
}


