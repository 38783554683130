import React from "react";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";
import ComparativoHeader from "./ComparativoHeader.js";
import ComparativoTabela from "./ComparativoTabela.js";
import HistoriaSistemas from "views/historia/HistoriaSistemas.js";

function Comparativo() {
  React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        <div className="main">
          <ComparativoHeader />
          <ComparativoTabela />
          <HistoriaSistemas />
        </div>
        <Footer />

      </div>
      <img
          alt="wpp-fab"
          onClick={() => window.open("https://wa.me/554530569085")}
          className="wpp-fab"
          src={require("assets/img/wpp.png")}
      ></img>
    </>
  );
}

export default Comparativo;
