import React from "react";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";
import EmissorHeader from "./EmissorHeader.js";
import EmissorSistemas from "./EmissorSistemas.js";
import EmissorConheca from "./EmissorConheca.js";
import EmissorTitulo from "./EmissorTitulo.js";
import EmissorBeneficios from "./EmissorBeneficios.js";
import EmissorRecursos from "./EmissorRecursos.js";

function Emissor() {
  React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        <div className="main">
          <EmissorHeader />
          <EmissorTitulo />
          <EmissorBeneficios />
          <EmissorRecursos />
          <EmissorConheca />
          <EmissorSistemas />
        </div>
        <Footer />
        <img
            alt="wpp-fab"
            onClick={() => window.open("https://wa.me/554530569085")}
            className="wpp-fab"
            src={require("assets/img/wpp.png")}
        ></img>
      </div>
    </>
  );
}

export default Emissor;
