import React, {Component} from "react";
import { Container, Row, Col } from "reactstrap";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { isMobile } from "react-device-detect";

class LightSistemas extends Component {

  state = {
    btnSelected: 0
  }

  render() {
    const responsive = {
      superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 4
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 4
      },
      tablet: {
        breakpoint: { max: 1024, min: 650 },
        items: 3
      },
      mobile: {
        breakpoint: { max: 650, min: 0 },
        items: 1
      }
    };

    return (
      <>
        <div className="section element historia-sistemas">
          <Container className="text-center">
            <Row>
              <Col lg="12" md="12">
                <p>
                <span data-aos="fade-up" className="sistemas-subtitulo">
                    SEJA ATENDIDO PELA MELHOR EQUIPE DE
                </span><br/>
                <span data-aos="fade-up" className="sistemas-titulo">
                    SUPORTE ESPECIALIZADO
                </span><br/>
                <span data-aos="fade-up" className="sistemas-subtitulo2">
                    DO BRASIL
                </span><br/>
                </p>
                <div className="sistemas-card">
                    <p className="sistemas-card-text">
                    Atendimento de suporte segunda-feira a sexta-feira das 8h às 18h e nos sábados das 8h às 12h.
                    Atendimento de plantão todos os dias até às 22 horas, incluindo sábados, domingos e feriados!<br/>
                    </p>
                </div>  

                <div>
                    <p>
                        <br/>
                        <span data-aos="fade-up" className="sistemas-subtitulo">
                            CONHEÇA MELHOR OS SISTEMAS OFERTADOS
                        </span>
                        <br/>
                    </p>
                </div>    
              </Col>
            </Row>
          </Container>
          <Carousel 
            swipeable={isMobile ? true : false}
            draggable={false}
            responsive={responsive} 
            className="sistemas-carousel"
            infinite={true}
            containerClass="carousel-container"
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-custom"
            removeArrowOnDeviceType={["mobile"]}
          >
             <Col lg="12" md="12" sm="12">
                <div className="sistemas-slider-card" onClick={() => window.location.href="/comparativo"}> 
                <img
                  alt="sistema-light"
                  className="sistemas-card-img pointer"
                  src={require("assets/img/sistema-comparativo.jpg")}
                ></img>
                </div>  
            </Col>
            
            <Col lg="12" md="12" sm="12">
                <div className="sistemas-slider-card" onClick={() => window.location.href="/emissor"}>
                <img
                  alt="sistema-light"
                  className="sistemas-card-img pointer"
                  src={require("assets/img/sistema-emissor.jpg")}
                ></img>
                </div>  
            </Col>

            <Col lg="12" md="12" sm="12">
                <div className="sistemas-slider-card" onClick={() => window.location.href="/manager"}>
                <img
                  alt="sistema-light"
                  className="sistemas-card-img pointer"
                  src={require("assets/img/sistema-manager.jpg")}
                ></img>
                </div>  
            </Col>
{/* 
            <Col lg="12" md="12" sm="12">
                <div className="sistemas-slider-card" onClick={() => window.location.href="/gourmet"}>
                <img
                  alt="sistema-light"
                  className="sistemas-card-img pointer"
                  src={require("assets/img/sistema-gourmet.jpg")}
                ></img>
                </div>  
            </Col> */}

            <Col lg="12" md="12" sm="12">
                <div className="sistemas-slider-card" onClick={() => window.open("https://provendascrm.com.br/index")}>
                <img
                  alt="sistema-light"
                  className="sistemas-card-img pointer"
                  src={require("assets/img/sistema-provendas.jpg")}
                ></img>
                </div>  
            </Col>
          </Carousel>
        </div>
      </>
    );
  }
}

export default LightSistemas;