import React, {Component} from "react";
import { Container, Row, Col } from "reactstrap";

class Titulo extends Component {

  state = {
    btnSelected: 0
  }

  render() {
    return (
      <>
        <div className="section element">
          <Container className="text-center">
            <Row className="justify-content-md-center">
              <Col lg="12" md="12">
                <span className="home-titulo">
                  POR QUE A GERMAN TECH É A OPÇÃO CERTA PARA SUA EMPRESA?
                </span>
                <h4 className="home-subtitulo">
                  Imagine ter um sistema que possui ferramentas específicas que de imediato
                  reduzem custos, automatizam tarefas e aumentam sua rentabilidade.
                  Maravilhoso, concorda?! Nós vamos além!
                </h4>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

export default Titulo;