import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

// styles for this kit
import "assets/css/bootstrap.min.css";
import "assets/scss/now-ui-kit.scss";
import "assets/demo/demo.css";
import "assets/css/germantech.css";
import "assets/css/500w.css";
import "assets/css/900w.css";
import "assets/css/1100w.css";
import "assets/css/1366w.css";
import "assets/css/1450w.css";
import "assets/css/1600w.css";
import "assets/css/1920w.css";
import "assets/demo/nucleo-icons-page-styles.css";
// pages for this kit
import Home from "views/home/index.js";
import Historia from "views/historia/index.js";
import Emissor from "views/sistemas/emissor/index.js";
import Light from "views/sistemas/light/index.js";
import Manager from "views/sistemas/manager/index.js";
// import Gourmet from "views/sistemas/gourmet/index.js";
import Comparativo from "views/sistemas/comparativo/index.js";
import Contato from "views/contato/index.js";

import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
// ..
AOS.init();

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Switch>
        <Route path="/index" render={props => <Home {...props} />} />
        <Route path="/nossa-historia" render={props => <Historia {...props} />} />
        <Route path="/emissor" render={props => <Emissor {...props} />} />
        <Route path="/light" render={props => <Light {...props} />} />
        <Route path="/manager" render={props => <Manager {...props} />} />
        {/* <Route path="/gourmet" render={props => <Gourmet {...props} />} /> */}
        {/* <Route path="/pro-vendas" render={props => <ProVendas {...props} />} /> */}
        <Route path="/comparativo" render={props => <Comparativo {...props} />} />
        <Route path="/contatos" render={props => <Contato {...props} />} />
        <Redirect to="/index" />
      </Switch>
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
