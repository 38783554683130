/*eslint-disable*/
import React from "react";
import { Container, Row, Col, Button } from "reactstrap";

function ComparativoTabela() {
  return (
    <>
      <div className="element" style={{display: 'block'}}>
            <div className="justify-content-md-center">
              <Col lg="12" md="12" className='comparativo-titulo'>
                <span>PRINCIPAIS RECURSOS DE CADA SISTEMA</span><br /><br />
              </Col>
            </div>

            <Row className="comparativo-row" style={{ marginLeft: "15%"}}>
              <div className="comparativo-col1" style={{textAlign: 'center', paddingTop: 45}}>
                <img
                    alt=""
                    className="comparativo-conheca pointer"
                    onClick={() => window.location.href="/contatos"}
                    src={require("assets/img/conheca-nosso-trabalho.png")}
                ></img>
                {/* <div className="comparativo-sociais">
                    <img
                        alt=""
                        className="comparativo-social-btn pointer"
                        src={require("assets/img/wpp-comparativo.png")}
                        onClick={() => window.open("https://wa.me/554530569085")}
                    ></img>
                    <img
                        alt=""
                        className="comparativo-social-btn pointer"
                        src={require("assets/img/telefone-comparativo.png")}
                    ></img>
                    <img
                        alt=""
                        className="comparativo-social-btn pointer"
                        src={require("assets/img/instagram-comparativo.png")}
                        onClick={() => window.open("https://www.instagram.com/sistemasgermantech/")}
                    ></img>
                    <img
                        alt=""
                        className="comparativo-social-btn pointer"
                        src={require("assets/img/facebook-comparativo.png")}
                        onClick={() => window.open("https://www.facebook.com/germantechsistemas/")}
                    ></img>
                    <img
                        alt=""
                        className="comparativo-social-btn pointer"
                        src={require("assets/img/youtube-comparativo.png")}
                        onClick={() => window.open("https://bit.ly/germantechsistemas")}
                    ></img>
                </div> */}
                <div className="comparativo-rounded">
                    <div className="comparativo-textos">
                        <div className="comparativo-inner-texto">
                            Ponto de Venda (PDV)
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-inner-texto">
                        Nota Fiscal de Consumidor
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-inner-texto">
                        Nota Fiscal de Serviço
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-inner-texto">
                        Nota Paulista
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-inner-texto">
                        Suporte e Atualizações
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-inner-texto">
                        Cadastro Automático de Produtos
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-inner-texto">
                        Cadastro de Clientes e Fornecedores
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-inner-texto">
                        Compatibilidade com Certificados A1 e A3
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-inner-texto">
                        Backup Automático em Nuvem
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-inner-texto">
                        Parametrização Automática de Produtos
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-inner-texto">
                        Integração com Balança
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-inner-texto">
                        Controle de Caixa e Estoque
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-inner-texto">
                        Emissão de Orçamentos e Pedidos
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-inner-texto">
                        Emissão de Ordem de Serviço
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-inner-texto">
                        Atualização Automática de Estoque
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-inner-texto">
                        Dashboard Interativo
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-inner-texto">
                        Controle de Acesso por Usuário
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-inner-texto">
                        Controle de Trocas e Devoluções
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-inner-texto">
                        Controle de Condicionais
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-inner-texto">
                        Relatórios Gerenciais
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-inner-texto">
                        Integração com Aplicativo
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-inner-texto">
                        Controle de Delivery
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-inner-texto">
                        Função de Notificação, Aviso e Agenda
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-inner-texto">
                        Controle de Contas a Pagar e a Receber
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-inner-texto">
                        Controle de Movimentações Bancárias
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-inner-texto">
                        Emissão de Promissórias e Recibos
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-inner-texto">
                        Controle de Cheques Emitidos/Recebidos
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-inner-texto">
                        Gestão de Vendas a Prazo/Crediário
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-inner-texto">
                        Cardápio Digital
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-inner-texto">
                        Controle de Reservas de Mesas
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-inner-texto">
                        Comanda Eletrônica
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-saiba-mais2" >
                        </div>
                        
                        
                    </div>
                      
                </div>
              </div>
              <div className="comparativo-col2">
                <div className="comparativo-rounded">
                    <img
                        alt=""
                        className="comparativo-sistema"
                        src={require("assets/img/sistema-l.jpg")}
                    ></img>
                    <div className="comparativo-emissor-titulo">
                        EMISSOR
                    </div>
                    <img
                        alt=""
                        onClick={() => window.location.href="/contatos"}
                        className="comparativo-contato pointer"
                        src={require("assets/img/entre-em-contato.png")}
                    ></img>
                    <div className="comparativo-divs">
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/not-check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/not-check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/not-check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/not-check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/not-check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/not-check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/not-check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/not-check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/not-check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/not-check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/not-check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/not-check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/not-check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/not-check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/not-check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/not-check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/not-check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/not-check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/not-check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/not-check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>

                        <div className="comparativo-saiba-mais" onClick={() => window.location.href="/emissor"}>
                            <img
                                alt=""
                                className="comparativo-img-saiba-mais pointer"
                                src={require("assets/img/saiba-mais.png")}
                            ></img>
                        </div>

                    </div>
                      
                </div>
              </div>
              <div className="comparativo-col3">
              <div className="comparativo-rounded">
                    <img
                        alt=""
                        // onClick={() => window.open("https://wa.me/554530569085")}
                        className="comparativo-sistema"
                        src={require("assets/img/sistema-ll.jpg")}
                    ></img>
                    <div className="comparativo-light-titulo">
                        LIGHT
                    </div>
                    <img
                        alt=""
                        onClick={() => window.location.href="/contatos"}
                        className="comparativo-contato pointer"
                        src={require("assets/img/entre-em-contato-l.png")}
                    ></img>
                    <div className="comparativo-divs">
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/not-check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/not-check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/not-check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/not-check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/not-check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/not-check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/not-check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/not-check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-saiba-mais" onClick={() => window.location.href="/light"}>
                            <img
                                alt=""
                                className="comparativo-img-saiba-mais pointer"
                                src={require("assets/img/saiba-mais.png")}
                            ></img>
                        </div>

                    </div>
                </div>
                      
              </div>
              <div className="comparativo-col4">
              <div className="comparativo-rounded">
                    <img
                        alt=""
                        className="comparativo-sistema"
                        src={require("assets/img/sistema-m.jpg")}
                    ></img>
                    <div className="comparativo-manager-titulo">
                        MANAGER
                    </div>
                    <img
                        alt=""
                        onClick={() => window.location.href="/contatos"}
                        className="comparativo-contato pointer"
                        src={require("assets/img/entre-em-contato-m.png")}
                    ></img>
                    <div className="comparativo-divs">
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>

                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/not-check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/not-check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/not-check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-saiba-mais" onClick={() => window.location.href="/manager"}>
                            <img
                                alt=""
                                className="comparativo-img-saiba-mais pointer"
                                src={require("assets/img/saiba-mais.png")}
                            ></img>
                        </div>

                    </div>
                </div>
              </div>
              {/* <div className="comparativo-col5">
              <div className="comparativo-rounded">
                    <img
                        alt=""
                        className="comparativo-sistema"
                        src={require("assets/img/sistema-g.jpg")}
                    ></img>
                    <div className="comparativo-gourmet-titulo">
                        GOURMET
                    </div>
                    <img
                        alt=""
                        onClick={() => window.location.href="/contatos"}
                        className="comparativo-contato pointer"
                        src={require("assets/img/entre-em-contato-g.png")}
                    ></img>
                    <div className="comparativo-divs">
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>
                        <div className="comparativo-div-checks">
                            <img
                                alt=""
                                className="comparativo-check"
                                src={require("assets/img/check.png")}
                            ></img>
                            <hr className="solid hr-comparativo"></hr>
                        </div>

                        <div className="comparativo-saiba-mais pointer" onClick={() => window.location.href="/gourmet"}>
                            <img
                                alt=""
                                className="comparativo-img-saiba-mais"
                                src={require("assets/img/saiba-mais.png")}
                            ></img>
                        </div>

                    </div>
                </div>
              </div> */}
            </Row>
        </div>
    </>
  );
}

export default ComparativoTabela;